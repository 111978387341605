<template>
  <div class="dashboard">
    <div class="dashboard__header">
      <h3 class="dashboard__header-title">Source Members Dashboard</h3>
    </div>
    <div class="dashboard__top-block">
      <DashboardUserData />
      <div class="dashboard__qr">
        <DashboardQR />
      </div>
    </div>
    <DashboardUserBalance v-if="isShowBalanceBlock" />
    <div class="dashboard__main-block">
      <DashboardReferrals />
      <DashboardLevels />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DashboardUserData from '@/components/Profile/DashboardUserData.vue';
import DashboardUserBalance from '@/components/Profile/DashboardUserBalance.vue';
import DashboardQR from '@/components/Profile/DashboardQR.vue';
import DashboardReferrals from '@/components/Profile/DashboardReferrals.vue';
import DashboardLevels from '@/components/Profile/DashboardLevels.vue';

export default {
  components: {
    DashboardUserData,
    DashboardUserBalance,
    DashboardQR,
    DashboardReferrals,
    DashboardLevels,
  },
  computed: {
    ...mapGetters(['getProfile']),
    isShowBalanceBlock() {
      return (
        this.getProfile.bsc_receive_address ||
        this.getProfile.cosmos_receive_address ||
        this.getProfile.srcx_receive_address ||
        this.getProfile.source_receive_address
      );
    },
  },
};
</script>

<style lang="sass" scoped>
.dashboard
  padding: 2.4rem
  display: flex
  flex-direction: column
  gap: 2.4rem
  flex: 0 0 100%
  z-index: 3
  @include m
    padding: 0
    gap: 0
    // @include xl
  //   padding: 4.8rem 2.4rem 2.4rem

.dashboard__header
  display: flex
  justify-content: space-between
  align-items: center
  gap: 2.4rem
  @include xl
    display: none

.dashboard__header-title
  margin-right: auto
  font-size: 3.2rem
  line-height: 3.8rem
  font-weight: 600

.dashboard__top-block
  display: flex
  justify-content: space-between
  gap: 2.4rem
  @include m
    flex-wrap: wrap
    flex-direction: column

.dashboard__main-block
  display: flex
  gap: 2.4rem
  @include xl
    flex-wrap: wrap
  @include m
    background: rgb(46,43,86)
    background: linear-gradient(180deg, rgba(46,43,86,1) 0%, rgba(0,0,0,1) 100%)
    padding-top: 2.8rem

.dashboard__qr
  @include xl
    display: none
  @include m
    display: block
    cursor: pointer
</style>
