<template>
  <div class="levels">
    <h3 class="levels__title">Member ranking levels</h3>
    <div class="levels__table-header">
      <p class="text">Rank</p>
      <p class="text">Description</p>
      <p class="text">Number</p>
    </div>
    <div class="levels__table-items">
      <div
        v-for="item in paginatedLevels"
        :key="item.rank"
        class="levels__table-item"
      >
        <div class="levels__number-block">
          <p class="levels__number-table text">Rank</p>
          <p class="levels__number text">{{ item.rank }}</p>
        </div>
        <div class="levels__description-block">
          <p class="levels__description-table text">Description</p>
          <p class="levels__description text">{{ item.description }}</p>
        </div>
        <div class="levels__number-block">
          <p class="levels__number-table text">Number</p>
          <p class="levels__number text">{{ item.referrals_count_needed }}</p>
        </div>
      </div>
    </div>
    <!-- <ButtonBase
      v-if="itemsToShow < getRankingLevels.length"
      class="levels__control"
      transparent
      @click="seeMore"
      >See more</ButtonBase
    > -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {},
  props: {},
  data() {
    return {
      itemsToShow: 4,
    };
  },
  computed: {
    ...mapGetters(['getRankingLevels', 'getWindowWidth']),
    paginatedLevels() {
      // let paginatedLevels = this.getRankingLevels;
      // return this.getWindowWidth >= 767
      //   ? this.getRankingLevels
      //   : paginatedLevels.slice(0, this.itemsToShow);
      return this.getRankingLevels;
    },
  },
  methods: {
    seeMore() {
      this.itemsToShow += 4;
    },
  },
};
</script>

<style lang="sass" scoped>
.levels
  background-color: $background-profile-color
  border-radius: 8px
  padding: 3.6rem 2.4rem
  flex: 1 0 calc(50% - 1.2rem)
  @include xl
    flex: 1 0 100%
  @include m
    background-color: transparent
    text-align: center
    padding: 0.4rem 0 3.6rem

.levels__title
  @include m
    margin-bottom: 2.4rem

.levels__table-item,.levels__table-header
  display: grid
  grid-template-columns: 1fr 8fr 1fr
  padding: 1.2rem 1rem
  align-items: center
  justify-content: center

.levels__table-header
  margin-top: 2.4rem
  text-align: center
  & p
    color: $subtitle-color
  @include m
    display: none

.levels__table-items
  display: flex
  flex-direction: column
  gap: 0.6rem
  @include m
    gap: 1.2rem

.levels__table-item
  background-color: $background-profile-darker-color
  border-radius: 2px
  @include m
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 2.4rem
    padding: 2.4rem 1.6rem

.levels__number-block
  display: flex
  gap: 1.2rem
  align-items: center
  justify-content: center

.levels__number
  text-align: center
  color: #31A8FA
  font-weight: 700
  @include m
    background-color: $background-profile-darker-color
    padding: 0.3rem 0.8rem
    border-radius: 4px

.levels__number-table
  color: $white
  display: none
  background-color: transparent
  @include m
    display: inline

.levels__control
  margin: 4rem auto 0
  display: none
  @include m
    display: block

.levels__description-block
  padding-left: 5rem
  @include m
    padding-left: 0
    order: 3

.levels__description
  @include m
    background-color: $background-profile-darker-color
    padding: 0.8rem 0.8rem
    border-radius: 4px

.levels__description-table
  text-align: start
  margin-bottom: 1.2rem
  display: none
  @include m
    display: block
</style>
