<template>
  <div class="levels">
    <h3 class="levels__title">My Source Force Referrals</h3>
    <div class="levels__table-header">
      <p class="levels__name text">Name</p>
      <p class="text">Rank</p>
      <p class="text">Member Date</p>
    </div>
    <div v-if="getReferrals.length" class="levels__table-items">
      <div
        v-for="item in getReferrals"
        :key="item.uuid"
        class="levels__table-item"
      >
        <div class="levels__user">
          <UserpicBase
            table
            :photo-url="item.photo_url"
            :last-name="item.last_name"
            :first-name="item.first_name"
          />
          <p class="text">{{ item.first_name }} {{ item.last_name }}</p>
        </div>
        <p class="levels__number text">
          <span class="levels__number-text">Rank </span
          ><span class="levels__number-border">{{ item.affiliate_rank }}</span>
        </p>
        <p class="levels__date text">
          <span class="levels__number-text">Member Date </span
          ><span class="levels__number-border">{{ item.member_date }}</span>
        </p>
      </div>
    </div>
    <div v-else class="levels__table-items-empty">
      <p class="text empty-title">There is nothing here</p>
      <p class="text empty-subtitle">Send a referral link to your friends</p>
    </div>

    <ButtonBase
      v-if="getReferrals.length < getProfile.total_referrals_count"
      class="levels__control"
      transparent
      @click="seeMore"
      >See more</ButtonBase
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import UserpicBase from '@/elements/UserpicBase.vue';

export default {
  components: { UserpicBase },
  props: {},
  data() {
    return {
      offset: 0,
    };
  },
  computed: {
    ...mapGetters(['getReferrals', 'getWindowWidth', 'getProfile']),
  },
  methods: {
    async seeMore() {
      this.offset += 10;
      await this.$store.dispatch('getReferrals', this.offset);
    },
  },
};
</script>

<style lang="sass" scoped>
.levels
  background-color: $background-profile-color
  border-radius: 8px
  padding: 3.6rem 2.4rem
  flex: 1 0 calc(50% - 1.2rem)
  display: flex
  flex-direction: column
  @include xl
    flex: 1 0 100%
  @include m
    background-color: transparent
    text-align: center
    padding: 1.2rem 0

.levels__title
  @include m
    margin-bottom: 2.4rem

.levels__table-item,.levels__table-header
  display: grid
  grid-template-columns: 5fr 6fr 12rem
  padding: 1.2rem 2.4rem
  align-items: center
  justify-content: center

.levels__table-header
  margin-top: 2.4rem
  text-align: center
  & p
    color: $subtitle-color
  @include m
    display: none

.levels__table-items
  display: flex
  flex-direction: column
  gap: 0.6rem
  @include m
    gap: 1.2rem

.levels__table-items-empty
  padding: 9.4rem 2.4rem
  background-color: $background-profile-darker-color
  border-radius: 2px
  height: 100%
  width: 100%
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 1rem
  @include xl
    padding: 10.4rem 2.4rem
  @include m
    padding: 9rem 1.6rem

.empty-title
  font-weight: 600

.empty-subtitle
  font-weight: 400

.levels__table-item
  background-color: $background-profile-darker-color
  border-radius: 2px
  @include m
    display: flex
    flex-direction: column
    align-items: flex-start
    gap: 1.2rem
    padding: 2.4rem 1.6rem
    & div
      margin-bottom: 1.2rem

.levels__number
  text-align: center
  font-weight: 700

.levels__number-text
  display: none
  @include m
    display: inline

.levels__number-border
  @include m
    background-color: $background-profile-darker-color
    padding: 0.3rem 0.8rem
    border-radius: 4px
    margin-left: 1.2rem

.levels__user
  display: flex
  gap: 1.2rem
  align-items: center

.levels__date
  text-align: center

.levels__name
  text-align: start

.levels__control
  margin: 4rem auto 0
</style>
